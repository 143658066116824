module.exports = {
  intro: {
    title1: "ポイントによる支払いの導入",
    title2: "アヤナリワードによる",
    subtitle:
      "アヤナ リワード ポイントをアヤナ ホテルでの忘れられない体験に変えましょう",
    cta: "料金を確認する",
  },
  title: "アヤナ限定の休暇をポイントでお支払いください",
  pros: {
    first: {
      title: "他にはない支払いの柔軟性を体験してください",
      description:
        "アヤナ ホテルの宿泊料金をポイントで簡単にお支払いいただけます。 ポイントが不足した場合は、現金で補ってさらに充実した体験を得ることができます。",
    },
    second: {
      title: "節約を最大限に活用する",
      description:
        "蓄積したポイントを会員限定の割引に交換し、比類のない節約で体験を向上させましょう。",
    },
  },
  preferred_dest: {
    title: "ご希望のアヤナの目的地をお選びください",
    subtitle:
      "アヤナ ホテルはジャカルタ、バリ、コモドにまたがっており、アヤナ ヴィラズ バリ、アヤナ セガラ バリ、アヤナ リゾート バリ、リンバ バイ アヤナ バリ、アヤナ コモド ワエチク ビーチ、アヤナ ラコ ディア、アヤナ ミッドプラザ ジャカルタ、デロニクスなどの有名ブランドを擁しています。 ホテルカラワン。",
    bali: "バリ島",
    jakarta: "ジャカルタ都市圏",
    komodo: "コモド",
    RJB: "リンバ by アヤナ バリ",
    ARSB: "アヤナ リゾート バリ",
    VILLA: "アヤナヴィラズ バリ",
    AKWB: "アヤナ コモド ワエチチュ ビーチ",
    LKD: "アヤナ ラコ ディア号",
    DXKR: "デロニクスホテル カラワン",
    JKTHB: "アヤナミッドプラザ ジャカルタ",
    SEGARA: "アヤナ セガラ バリ",
  },
  how_it_works: {
    title:
      "アヤナホテルでは、全ホテルでポイントを使ってお支払いできるようになりました。",
    subtitle: "手続き方法は、以下の通りです。",
    first: "下の「ホテルを探す」ボタンをクリックします。",
    second: "ご希望の宿泊日と場所を選択",
    third: "アヤナリワードポイント利用可能な料金を選択",
    fourth: "アヤナリワードアカウントにログイン",
    fifth: "ポイントで支払うに進む",
    sixth: "ご滞在をお楽しみください!",
  },
  find_hotels: {
    title: "あなたのポイントを忘れられないアヤナでの滞在へと交換しましょう！",
    cta: "ホテルを探す",
  },
  faq: {
    title: "よくある質問",
    first: {
      question:
        "アヤナ リワード ポイントを使用した予約の作成はどのように機能しますか?",
      answer:
        "このオプションを選択すると、予約に必要なポイント数をカバーするためにポイントが使用されます。 予約の支払いに引き換えるポイント数を決定し、他の利用可能な支払い方法を使用して残りの予約金額を支払うことができます。",
    },
    second: {
      question:
        "ポイントを使用して支払いをした場合、予約をキャンセルできますか?",
      answer:
        "予約のキャンセルポリシーは、選択した料金のキャンセルポリシーに従います。選択した料金が払い戻し可能な場合、ポイントで支払った金額はポイントとして返金され、別の支払い方法で支払った部分はその支払い方法に従って返金されます。",
    },
    third: {
      question:
        "アヤナ リワード ポイントで予約を支払った場合、ポイントを獲得できますか?",
      answer:
        "はい、当社のウェブサイトを通じて作成され、アヤナ リワード ポイントを使用して支払われた予約の対象となる料金については、引き続きポイントを獲得できます。 対象となる費用はホテルによって異なります。 詳細については、利用規約 を参照してください。 アヤナ リワードは、将来予告なくポリシーを変更する権利を留保しますので、ご了承ください。",
    },
  },
  tnc: "利用規約",
};
